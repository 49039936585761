import React,{useEffect,useState} from 'react'
import { Helmet } from 'react-helmet-async'
import General from './../components/Main/Payment'
import {useParams } from 'react-router-dom'
import PricingOption from '../../src/components/Main/Course/PricingOption'
import axios from 'axios'
const Payment = () => {
const { id } = useParams()
const [courseData, setCourseData] = useState([])
const [isLoadin,setLoading]=useState(true)
const [price,setPrice]=useState()
const [code,setCode]=useState("")

const getData = async () => {
    await axios.get(`https://api.asgarzademusicschool.com/api/v1/Course/GetById/${id}`).then((res) =>{ setCourseData(res.data.data); setLoading(false);});
    
}

const handlePrice=(discountPrice)=>{
  setPrice(discountPrice);
}
const handleCode=(value)=>{
  setCode(value);
}
useEffect(() => {
    const fetchData = async () => {
        await getData();
    }

    fetchData();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(()=>{
  if (courseData && courseData.price) {

    if(courseData.price!=0&&courseData.price>courseData.discountPrice){
      setPrice( courseData?.discountPrice === 0 ? 
        courseData.price :courseData?.discountPrice );
     
    }else{
      setPrice(courseData.price);
    }
   
}
},[courseData])


 if(!isLoadin){
  return (
    <>
      <Helmet>
        <title>Payment | Asgarzade Music School</title>
        <meta name='description' content='It is about Payment page of Music School' />
      </Helmet>
      <General  price={price} title={courseData.title} image={courseData.image}  handlePrice={handlePrice} courseId={courseData.id} code={code} handleCode={handleCode} />
      <PricingOption price={price} code={code} handleCode={handleCode} isMonth={courseData.isMonth}/>
    </>
  )
 }
}

export default Payment