import React,{useEffect,useState} from 'react'
import './ManageSubscription.scss'
import { Link,useNavigate } from 'react-router-dom'
import axios from 'axios'

const ManageSubscription = () => {
const [enrollments,setEnrollments]=useState([]);
const navigate=useNavigate();
const [isLoading,setIsLoading]=useState(true);
const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
useEffect(() => {
    const token=localStorage.getItem("token");
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `bearer ${token}`
        }
      };
      
    try {
       const response= axios.get("https://api.asgarzademusicschool.com/api/v1/CourseEnroll/GetCorusesOfCurrentUser",
        axiosConfig);
        response.then(res=>{
            if(res?.status===200)
          {
                setEnrollments(res.data.data)
                setIsLoading(false)
          }
        }).catch(error=>{
            if(error.response.status===401){
                navigate("/login")
            };
        })
    } catch (error) {
    }
     
  },[]);


    if(!isLoading){
        return (
            <div className='subscription'>
              {enrollments.length>0?
                <div className='subscription-head'>
                <h6>Your Courses</h6>
                 
                 {
                    enrollments.map((enroll,index)=>(
                        <ul key={index}>
                        <li>{enroll.courseName}</li>
                        <li>{enroll.totalPrice} $</li>
                        <li>{formatDate(enroll.createdAt)}</li>
                        <li style={{cursor:'pointer',color:'#d58b0e'}} onClick={ ()=> navigate(`/video/${enroll.courseId}`)}>Go to course</li>

                </ul>
                    ))
                 }
    
                <div className='line'></div>
            </div>:
             <div className='subscription-empty'>
             <p>You currently do not have any subscriptions.</p>
             <p>You can take a look at our active courses <Link to="/courses">here</Link>.</p>
         </div> 
            }
          
               
            </div>
        )
    }
}

export default ManageSubscription