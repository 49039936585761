import React,{useEffect,useState,useRef} from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import VideosContent from '../components/Main/Videos'
import {getLoadingStatus} from "../services/checkRefresh"
const Video = () => {
    const { id } = useParams();
     const [isLoading, setLoading] = useState(true);
     const intervalRef = useRef(null);
     useEffect(() => {
        const checkStatus = async () => {
            const status = await getLoadingStatus();
            setLoading(status);
            if (!status) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };

        if (isLoading) {
            intervalRef.current = setInterval(checkStatus, 1000);
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [isLoading]);

    if(isLoading){
        return (<h1>...loading</h1>)
    }
    return (
        <>
            <Helmet>
                <title>Video | Asgarzade Music School</title>
                <meta name='description' content='It is about Video page of Music School' />
            </Helmet>
            <VideosContent id={id} />
        </>
    )
}

export default Video
