import React, { useState ,useEffect} from 'react'
import './VideosContent.scss'
import LeftSide from './LeftSide'
import RightSide from './RightSide'
import Header from './Header'
import {useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-hot-toast'
const VideosContent = ({id}) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [isSchemeOpen, setIsSchemeOpen] = useState(false);
    const [isLoading, SetLoading] = useState(true);
    const [courseId, setCourseId] = useState('');
    const [autoPlay, setAutoPlay] = useState(true);
    const navigate=useNavigate();
    const data = [
        {
            id: 1,
            note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Malesuada pellentesque elit eget gravida cum sociis natoque penatibus et. Faucibus in ornare quam viverra orci sagittis. Consequat semper viverra nam libero justo laoreet sit amet cursus. Malesuada proin libero nunc consequat interdum varius sit amet.',
            date: 'yesterday'
        },
        {
            id: 2,
            note: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
            date: 'today'
        }
    ]
    const [datas, setDatas] = useState([])
    const [courseData, setCourseData] = useState([]);
    useEffect(() => {
        const token = localStorage.getItem("token");
        fetch(`https://api.asgarzademusicschool.com/api/v1/Course/Get/${id}`, {
            headers: {
                'Authorization': `bearer ${token}`
            }
        })
        .then(response =>response.json())
        .then(data => {
            setCourseData(data.data);
            SetLoading(false);
            handleCourseId(data.data.id)
        })
        .catch(error => console.error("API'den veri çekerken bir hata oluştu:", error));
    }, [id]);
    
    const handleVideoSelect = (index) => {
        
        setSelectedItem(index);
        setIsSchemeOpen(false)
       
    };

    const handleAutoPlay=(value)=>{
        setAutoPlay(value);
    }
    const handleCourseId = (id) => {
        setCourseId(id)
       
    };

    const handleSchemeOpen = () => {
        setIsSchemeOpen(true)
        window.scrollTo(0, 0)
    }
    const handleCompleteAndContinue = () => {
        const allContents = courseData.chapterGetDtos.flatMap(chapter => chapter.courseContents);
        const currentIndex = allContents.findIndex(content => content.id === selectedItem);
        const nextIndex = currentIndex + 1;
        const token=localStorage.getItem("token")
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `bearer ${token}`
            }
          };
          var obj={
            courseContentId:selectedItem,
            percent:100,
            IsFinished:true
          }
        axios.post('https://api.asgarzademusicschool.com/api/v1/CourseContentActivity/CreateCourseContentActivity',obj,axiosConfig)
        .then(res=>{})
        .catch(error=>{
            toast.error("already completed")
        })
        if (nextIndex < allContents.length) {
          const nextVideoId = allContents[nextIndex].id;
          handleVideoSelect(nextVideoId);
          setAutoPlay(true)
        } else {
        }
      };
   if(isLoading){
    return (
        <>loading</>
    )
   }else{
    return (
        <>
            <Header enrolStatus={courseData.enrolStatus} onCompleteAndContinue={handleCompleteAndContinue} courseId={courseId}/>
            <div className='general-videos-content'>
                <LeftSide
                    setIsSchemeOpen={setIsSchemeOpen}
                    handleVideoSelect={handleVideoSelect}
                    handleCourseId={handleCourseId}
                    selectedItem={selectedItem}
                    isSchemeOpen={isSchemeOpen}
                    course={courseData}
                    handleAutoPlay={handleAutoPlay}
                     />
                <RightSide
                    selectedItem={selectedItem}
                    handleVideoSelect={handleVideoSelect}
                    handleSchemeOpen={handleSchemeOpen}
                    datas={datas}
                    setDatas={setDatas}
                    courseId={courseId}
                    autoPlay={autoPlay}
                />
            </div>
        </>

    )
   }
}

export default VideosContent