import React, { useState ,useEffect, useRef} from 'react'
import './PlayerSide.scss'
import { ReactComponent as FilmIcon } from './../../../../../assets/icons/Film.svg'
import { ReactComponent as Edit } from './../../../../../assets/icons/Edit.svg'
import { ReactComponent as Trash } from './../../../../../assets/icons/TrashDelete.svg'
import { ReactComponent as Lock } from './../../../../../assets/icons/LockWithBackground.svg'
import { ReactComponent as CheckboxOn } from './../../../../../assets/icons/Checkbox-on.svg'
import { ReactComponent as IconDow } from './../../../../../assets/icons/Download.svg';
import {ReactComponent as FileImage} from '../../../../../assets/icons/save-save-the-document-svgrepo-com.svg'
import { useNavigate } from 'react-router-dom'
import Modal from './Modal'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';
import userImage from '../../../../../assets/images/userDEfault.jpeg'
import { Viewer, Worker,OpenFile } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import  '@react-pdf-viewer/core/lib/styles/index.css';
import  '@react-pdf-viewer/default-layout//lib/styles/index.css';



const PlayerSide = ({ selectedVideoId, handleSchemeOpen, datas, setDatas,courseId ,autoPlay}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [newNote, setNewNote] = useState('');
  const [editNoteId, setEditNoteId] = useState(null);
  const [editedNoteText, setEditedNoteText] = useState('');
  const [content,setContent]=useState('');
  const [isLoading,setLoading]=useState(true);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);  
  const [fileName, setFileName] = useState('Add File');
  const [isSubmitting, setIsSubmitting] = useState(false); // Add this line
 const [showControls, setShowControls] = useState(false);
  const videoRef = useRef(null);


  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const { ZoomOut } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex"
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut>
                {(props) => (
                  <button
                    style={{
                      backgroundColor: "#357edd",
                      border: "none",
                      borderRadius: "4px",
                      color: "#ffffff",
                      cursor: "pointer",
                      padding: "8px"
                    }}
                    onClick={props.onClick}
                  >
                    Zoom out
                  </button>
                )}
              </ZoomOut>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

//   const getFilePluginInstance = getFilePlugin({
//     fileNameGenerator: (file) => {
//         const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
//         return `a-copy-of-${fileName}`;
//     },
// });
  const defaultLayoutPluginInstance = defaultLayoutPlugin();





  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    setSelectedFile(file)
    if (file) {
     
      const shortName = file.name.length > 20 ? file.name.substr(0, 17) + '...' : file.name;
      setFileName(shortName);
    } else {
      setFileName('Add File');
    }
  };
  const handleDelete = (item) => {
    setIsModalOpen(true);
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        'Authorization': `bearer ${token}`
      }
    };
   axios.delete(`https://api.asgarzademusicschool.com/api/v1/Comment/DeleteComment/${item.id}`,axiosConfig).then(
    res=>{
      if(res.status){
        toast.success("removed")
        setItemToDelete(item);
      }
    }
   ).catch(error=>{

   })
  }
  const navigate=useNavigate();
  useEffect(()=>{

  },[])



  function downloadImage(imageUrl) {
    // Create a link element
    const link = document.createElement('a');

    // Set the href attribute to the image URL
    link.href = imageUrl;

    // Set the download attribute to prompt the user to download the image
    link.download = 'image';

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click event on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
}
const handleLeaveNote = () => {
  if (newNote.trim() !== '') {
    setIsSubmitting(true); // Start submitting
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        'Authorization': `bearer ${token}`
      }
    };
    const formData = new FormData();
    formData.append("Text", newNote);
    formData.append("CourseContentId", selectedVideoId);
    if (selectedFile) {
      formData.append("File", selectedFile);
    }

    axios.post("https://api.asgarzademusicschool.com/api/v1/Comment/CreateComment",
      formData, axiosConfig)
      .then(res => {
        if (res.status) {
          toast.success("success");
          setNewNote('');
          setSelectedFile(null);
          setFileName("Add File")
          setDatas([...datas, { id: res.data.id, text: newNote, courseContentId: selectedVideoId, filePath: selectedFile, baseCommentId: null,createdAt:new Date() }]);
        } else {
          toast.error("add valid text");
        }
      }).catch(error => {
        toast.error("add valid text");
      }).finally(() => {
        setIsSubmitting(false); // Stop submitting regardless of the result
      });
  } else {
    

    toast.error("Please fill in the comment section and Choose a new file!");
    
  }
};

    const handleEnroll=(courseId)=>{
      navigate(`/courses/${courseId}`)
    }
    
    useEffect(()=>{
    if(selectedVideoId!=null&&selectedVideoId!==4&&selectedVideoId!==5){
      const token=localStorage.getItem("token")
      let fetchConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `bearer ${token}`
        }
      };
      fetch(`https://api.asgarzademusicschool.com/api/v1/CourseContent/GetById/${selectedVideoId}`,fetchConfig)
      .then(res=>res.json())
      .then(obj=>{setContent(obj.data)
        setDatas(obj.data?.comments)
        setLoading(false)
      })
    }else if(selectedVideoId===4){
      setLoading(false)
    }else if(selectedVideoId===5){
      setLoading(false)
    }
    },[selectedVideoId])
    const handleEditClick = (item) => {
      setEditNoteId(item.id);
      setEditedNoteText(item.text);
    };
    
    // updateCommentText fonksiyonu (Güncelleme)
    const updateCommentText = async (commentId, commentText) => {
      const token = localStorage.getItem("token");
      try {
        await axios.put(`https://api.asgarzademusicschool.com/api/v1/Comment/UpdateCommentText/${commentId}?commentText=${commentText}`,
        {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
             
            }
          }
        );
        toast.success("Comment updated successfully");
        // Yorum listesini güncelle
        const updatedDatas = datas.map(data => data.id === commentId ? { ...data, text: commentText } : data);
        setDatas(updatedDatas);
        // Düzenleme durumunu sıfırla
        setEditNoteId(null);
        setEditedNoteText('');
      } catch (error) {
        toast.error("Failed to update comment");
      }
    };
    
    // handleSaveClick fonksiyonu
    const handleSaveClick = () => {
      if (editNoteId && editedNoteText.trim() !== '') {
        updateCommentText(editNoteId, editedNoteText.trim());
      } else {
        toast.error("Please enter some text");
      }
    };
  
  const handleConfirmDelete = () => {
    if (itemToDelete) {
      const updatedData = datas.filter((item) => item.id !== itemToDelete.id);
      setDatas(updatedData);
    }
    setIsModalOpen(false);
    setItemToDelete(null);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setItemToDelete(null);
  };
  function openPopup(url) {
    // Popup penceresini aç
    const popupWindow = window.open(
      url,
      'popupWindow',
      'width=800,height=600,scrollbars=yes'
    );
    
    // Popup penceresine odaklan
    if (popupWindow) {
      popupWindow.focus();
    } else {
      alert('Popup blocked by browser');
    }
  }
  const handlePayment=()=>{
    const token=localStorage.getItem("token")
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': `bearer ${token}`
      }
    };
    axios.get(`https://api.asgarzademusicschool.com/api/v1/CourseEnroll/PaymentForNextMonth?id=${courseId}`,axiosConfig)
       .then(res=>{
        window.location= res.data.url
        setIsEnrolling(false);
       }).catch(error=>{
        toast.error('Something went wrang please try again!');
        setIsEnrolling(false);
       })
  }
  let style = {
    width: "100%",
    height: "calc(38vw + 125px)",
    marginTop: "calc(-70px)",
    width:"100%"
    
  };
  
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
 
  if(!isLoading){
    const sortedFiles = content?.courseFileGetDtos?.sort((a, b) => {
      const getFileExtension = (filePath) => filePath.split('.').pop().split('?')[0].toLowerCase();
      
      const aExtension = getFileExtension(a.filePath);
      const bExtension = getFileExtension(b.filePath);
  
      // Word dosyalarını en başa alın
      if ((aExtension === 'doc' || aExtension === 'docx') && !(bExtension === 'doc' || bExtension === 'docx')) {
          return -1;
      } else if (!(aExtension === 'doc' || aExtension === 'docx') && (bExtension === 'doc' || bExtension === 'docx')) {
          return 1;
      }
  
      return 0;
  });
    return (
      <div className='player-side-content'>
        <div className='player-side'>
          <div className="head">
            <FilmIcon className='icon-film' />
           
          </div>
          {selectedVideoId !== 4 && selectedVideoId !== 5  && (
//  <div className="video-area"
//  onMouseEnter={() => setShowControls(true)}
//  onMouseLeave={() => setShowControls(false)}
//  style={{ position: 'relative', cursor: 'pointer' }}>
// <video  className='videoTag'
//         ref={videoRef}

//     key={content?.videoLink} 
//     style={{ width: '100%', height: 'auto' }}
//         onMouseEnter={() => setShowControls(true)}
//         controls={showControls}
//     autoPlay ={autoPlay}
//     controlsList="nodownload"
//     src={content?.videoLink}
// >
   
//     Tarayıcınız video tag'ını desteklemiyor.
// </video>
// </div>

<div 
 onMouseEnter={() => setShowControls(true)}
 onMouseLeave={() => setShowControls(false)}
style={{ position: 'relative', cursor: 'pointer' }}>
<video  
 style={style}

 preload='auto'
       ref={videoRef}
        onMouseEnter={() => setShowControls(true)}
        controls
   key={content?.videoLink} 
  //  style={{ width: '100%', height: 'auto' }}
   autoPlay ={autoPlay}
   controlsList="nodownload"
   src={content?.videoLink}
>
  
   Tarayıcınız video tag'ını desteklemiyor.
</video>



</div>

          )}

          {selectedVideoId === 4 && (
            <div className="video-area-2">
              <div className='info'>
                <Lock />
                <div className='text'>
                  <span>Watching this video is restricted.
                  </span>
                  <p>Enroll in Course to access.</p>
                </div>
                <div onClick={e=>handleEnroll(courseId)}  className='button-info'>
                  <button>Buy now</button>
                  <span>If you're already enrolled, you'll need to login.</span>
                </div>
              </div>
            </div>
          )}
             {selectedVideoId === 5 && (
  <div className="video-area-2">
    <div className='info'>
      <Lock />
      <div className='text'>
        <span>Access to this video is pending.</span>
        <p>Complete the payment to start watching.</p>
      </div>
      <div onClick={()=>handlePayment()} className='button-info'>
        <button> {isEnrolling ? 'Processing...' : 'Complete Payment'}</button>
        <span>If you've completed your payment, please login or contact support.</span>
      </div>
    </div>
  </div>
)}
         <button className='see-parts' onClick={handleSchemeOpen}>See all parts</button>
        </div>
        <div className='notes'>
    {
       sortedFiles?.map((file, index) => {
        const fileExtension = file.filePath.split('.').pop().toLowerCase();
        
        switch (fileExtension.split('?')[0]) {
            case 'pdf':
                return (
                  <>
<div >
<a 

href={file.filePath} 
target="_blank" 
rel="noopener noreferrer" 
key={index}
style={{
    padding: '5px 7px',
    color: 'rgb(238, 155, 7)',
    border: '1px solid rgb(238, 155, 7)',

    marginBottom:"35px",
    // maxWidth:'350px',
    // maxWidth:'350px',
    textAlign: 'center',
    cursor:"pointer",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
    
}}
download={file.fileName}
>
<IconDow />
{file.fileName}
</a>
</div>
     <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div style={{ height: "720px", width:"100%"}}>

       <Viewer  
          download={false}
         plugins={[defaultLayoutPluginInstance]} fileUrl={file.filePath} />;
</div>
     </Worker>
                  </>
                 
                    
                );
            case 'doc':
            case 'docx':
                // Word dokümanı için bir link veya uygun bir görüntüleyici
                return (

                  <>
                  <a 

                  href={file.filePath} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  key={index}
                  style={{
                      padding: '5px 7px',
                      color: 'rgb(238, 155, 7)',
                      border: '1px solid rgb(238, 155, 7)',

                      marginBottom:"35px",
                      // maxWidth:'350px',
                      // maxWidth:'350px',
                      textAlign: 'center',
                      cursor:"pointer",
                      display:"flex",
                      justifyContent:"space-between",
                      alignItems:"center"
                  }}
                  download={file.fileName}
              >
            <IconDow />
                  {file.fileName}
              </a>
              <br></br>
                  </>
         
              
                );
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                // Resim dosyaları için bir img tag
                return (
                  <div>
                      <img
                          src={file.filePath}
                          alt={`Image ${index}`}
                          style={{ width: '100%', height: '600px', border: 'none' }}
                          onClick={() => downloadImage(file.filePath)}
                      />
                  </div>
              );
            default:
                // Diğer dosya türleri için genel bir çözüm
                return (
                    <a href={file.filePath} target="_blank" rel="noopener noreferrer" key={index} style={{
                      padding: '5px 7px',
                      color: 'rgb(238, 155, 7)',
                      border: '1px solid rgb(238, 155, 7)',
                      maxWidth:'350px',
                      textAlign: 'center',
                      cursor:"pointer",
                      display:"flex",
                      justifyContent:"space-between",
                      alignItems:"center"
                  }}>
                       {file.fileName}
                    </a>
                );
        }
    })
    
    }
</div>

{
  (content?.isAllowComment&&
    <div className='notes comment'>
    <div className="written-notes">
      <h5>ask a question</h5>
      <ul className="notes-area">
        
        {
          datas?.filter(item => !item.baseCommentId).map((item) => {
            console.log("item1",item);
            const replies = datas.filter(reply => reply.baseCommentId === item.id);
          
            return (
              <li key={item.id}>
                <span className='time'>{formatDate(item.createdAt)}</span>
                <div className='note'>
                  {editNoteId === item.id && item.appUserId !== "" ? (
                   <>
                    <textarea
                      value={editedNoteText}
                      onChange={(e) => setEditedNoteText(e.target.value)}
                    />
                    <CheckboxOn onClick={() => handleSaveClick()} />
                   </>
                  ) : (
                    <>
                     <div className='mainComment'>
                     <p className='parentUserComment'>
                        <img className='userImage' src={userImage} alt="user Image" />
                        <div className='nameAndComments'>
                          <p className='userName'>{item.userName}</p>
                          <p className='userComment'>{item.text}</p>

                          {item.filePath && (
<>
<img style={{width:"100%"}} src={item.filePath}></img>
<div className='fileComment'>

<br></br>
<FileImage style={{ marginRight: "8px" }} />
<a href={item.filePath}>
View File
</a>
</div>
</>
                        )}
                        </div>
                        {item.appUserId !== "" && (
                    <div className='right-side'>
                      {editNoteId === item.id ? (
                        <>
                         
                          <CheckboxOn onClick={handleSaveClick} />
                          <Trash className='icon-trash' onClick={() => handleDelete(item)} style={{ visibility: 'hidden' }} />
                        </>
                      ) : (
                        <>
                     
                          <Edit
                            className='icon-edit'
                            onClick={() => handleEditClick(item)}
                          />
                          <Trash className='icon-trash' onClick={() => handleDelete(item)} />
                        </>
                      )}
                    </div>
                  )}
                      </p>
                     
                     </div>
                      {replies.length > 0 && (
                        <div className='parentTeacherComment'>
                          <div className='teacherComment'>
                          <span>Reply: Teacher</span>
                          <div>
                          {replies.map(reply => (
                            <p key={reply.id} className='teacherCommentInfo' style={{marginBottom:"10px"}}>
                              {reply.text}
                              {reply.filePath && (
                          <div className='fileComment'>
                            <>
<img style={{width:"80%"}} src={reply.filePath}></img>

<br></br>
<FileImage style={{ marginRight: "8px" }} />
<a href={item.filePath}>
View File
</a>

</>
                          </div>)}
                             
                            </p>
                          ))}
                          </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  
                </div>
              </li>
            );
          })
          
        }
      </ul>
    </div>
    <div className="new-note">
      <textarea
        placeholder='Leave a note'
        value={newNote}
        onChange={(e) => setNewNote(e.target.value)}
      ></textarea>
      <label className='addFileLabel' htmlFor='addFile'>{fileName}</label>
      <input id='addFile' type="file" onChange={handleFileChange}/>
      <button onClick={handleLeaveNote} disabled={isSubmitting} className='LeaveNote'>
        <p>Leave a note {isSubmitting ? (<div class="loader"></div>) : ""}</p>
        </button>    
        </div>
  </div>
  )
}
       
     
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          handleConfirmDelete={handleConfirmDelete}
        />
                <Toaster toastOptions={{
    className: '',
    style: {
        duration: 3000,
      border: '1px solid #713200',
      padding: '16px',
      color: '#713200',
      zIndex:"999999999999999999999"
    },
  }} className="test"  position="top-center"
  reverseOrder={false} />
      </div>
    )
  }
}

export default PlayerSide