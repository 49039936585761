import React, { useEffect } from 'react'
import './Cards.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCourses } from '../../../../redux/actions/coursesActions'

const Cards = () => {
  const navigate = useNavigate();
  const handleClick = (courseId) => {
    navigate(`/video/${courseId}`);
    window.scrollTo(0, 0);
  }
  const dispatch = useDispatch()
  const { data } = useSelector((state) => state.courses)

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

//   return (
//     <section className='cards-courses'>
//       <div className="container">
//         <ul className='cards-courses-container'>
//           {data && data.items ? (
//             data.items.map((course) => (
//               <li key={course.id} onClick={() => handleClick(course.id)}>
//                 <div className='image-side'>
//                   <img src={course.image} alt="picture1" className='main_image' />
//                 </div>
//                 <div className='info_card'>
//                   <h6>{course.title}</h6>
//                   <div className='info_card_owner'>
//                     <div className='owner'>
//                     <span dangerouslySetInnerHTML={{ __html: course.shortInfo }}></span>
//                     </div>
//                     <span className={`price ${course.isComming==true?"green":""}`}>


// {
//     course.isComming==true?
//     "Coming Soon":
//     course?.discountPrice === 0 ? 
// "$"+ course.price : 
// <>
// <del style={{fontWeight:"lighter"}}>${course.price}</del>
// &nbsp; &nbsp; 

// <span style={{color: 'rgb(235 87 87)'}}>
// ${course.discountPrice} &nbsp; 
// SAVE ${course.price-course.discountPrice}
// </span>
// </>
    
// }
// {
// }
// </span>
// <p style={{textAlign:"right",marginTop:"10px"}}>(Excluding VAT)</p>

//                   </div>
//                 </div>
//               </li>
//             ))
//           ) : (
//             <p>No data available</p>
//           )
//           }
//         </ul>
        
//       </div>
//     </section >
//   )

return (
  <section className='available-courses' >
      <div className="container">
          <div className='available-courses-container'>
              <h2>
                  Available  <span className='special-header-text'>Courses</span>
              </h2>
              <ul className='available-courses-container_cards'>
                  {data && data.items ? (
                      data.items.map((course) => (
                          <li key={course.id} onClick={() => handleClick(course.id)}>
                              <div className='image-side'>
                                  <img src={course.image} alt="picture1" className='main_image' />
                              </div>
                              <div className='info_card'>
                                  <h6>{course.title}</h6>
                                  <div className='info_card_owner'>
                                      <div className='owner'>
                                      <span dangerouslySetInnerHTML={{ __html: course.shortInfo }}></span>
                                      </div>
                                      <span className={`price ${course.isComming==true?"green":""}`}>


                                          {
                                              course.isComming==true?
                                              "Coming Soon":
                                              course?.discountPrice === 0 ? 
  "$"+ course.price : 
  <>
      <del style={{fontWeight:"lighter"}}>${course.price}</del>
      &nbsp; &nbsp; 

      <span style={{color: 'rgb(235 87 87)'}}>
          ${course.discountPrice} &nbsp; 
           SAVE ${course.price-course.discountPrice}
      </span>
  </>
                                              
                                          }
{
}
</span> 
<p style={{textAlign:"right",marginTop:"10px"}}>{course.isTax===true? "(Including VAT)":"(Excluding VAT)"}</p>

                                  </div>
                              </div>
                          </li>
                      ))
                  ) : (
                      <p>No data available</p>
                  )
                  }
              </ul>
          </div>
      </div>
  </section>
);
}

export default Cards